.app-data-dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.app-data-cards-container {
  display: flex;
  flex-wrap: wrap;
}

.app-data-dashboard-card {
  width: 1000px;
  flex: 1 1 calc(33.33% - 20px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: transparent;
  padding: 10px;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-left: 3px solid #4caf50;
  border-bottom: 3px solid #708db6;
}

.app-data-dashboard-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.app-data-card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.app-data-card-icon {
  font-size: 20px;
  color: #4caf50;
  margin-left: 10px;
}

.app-data-custom-hr {
  border: 0;
  height: 1px;
  background-color: #d1e7fd;
  margin: 20px 0;
}

.app-data-card-value {
  font-size: 24px;
  color: #708db6;
  font-weight: bolder;
}

.app-data-main-content {
  font-size: 16px;
  color: #708db6;
}
