.dashboard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
}

.cards-container .dashboard-card:nth-child(4),
.cards-container .dashboard-card:nth-child(5) {
  flex: 1 1 calc(50% - 20px);
}

.dashboard-card {
  width: 185px;
  flex: 1 1 calc(33.33% - 20px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: transparent;
  padding: 10px;
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-left: 3px solid #4caf50;
  border-bottom: 3px solid #708db6;
}

.dashboard-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.not-admin .dashboard-card {
  width: 500px;
  flex: 1 1 calc(50% - 40px);
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card-icon {
  font-size: 20px;
  color: #4caf50;
  margin-left: 10px;
}

.custom-hr {
  border: 0;
  height: 1px;
  background-color: #d1e7fd;
  margin: 20px 0;
}

.card-value {
  font-size: 24px;
  color: #708db6;
  font-weight: bolder;
}

.main-content {
  font-size: 16px;
  color: #708db6;
}

@media (min-width: 1920px) {
  .dashboard-card {
    width: 350px;
  }
}
